import React, { useEffect, useState, lazy, Suspense } from "react"
import { Helmet } from "react-helmet-async"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import "./App.scss"
import "antd/dist/antd.min.css"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import { useVerifyMeQuery } from "store/reducers/authApi"
import { useAppDispatch } from "store/hooks"
import {
    getUserFail,
    getUser,
    getVerifySuccess,
} from "store/reducers/userSlice"
import OurMission from "components/publicPages/ourMission/OurMission"
import Home from "components/publicPages/home/Home"
import RequireAuth from "components/HOC/RequireAuth"
import DashBoardLayout from "components/layout/Layout"
import RequireRole from "components/HOC/RequireRole"
import AppLoader from "components/presentational/AppLoader"
import RequireUser from "components/HOC/RequireUser"
import ProtectedRouter from "components/HOC/ProtectedRouter"
const ExamSection = lazy(
    () => import("components/smart/pdfSection/ExamSection")
)
const AdminOfficeHours = lazy(
    () => import("components/smart/adminOfficeHours/AdminOfficeHours")
)
const CreateOfficeHour = lazy(
    () => import("components/smart/createOfficeHour/CreateOfficeHour")
)
const CreateBulkOfficeHours = lazy(
    () => import("components/smart/createBulkOfficeHours/CreateBulkOfficeHours")
)
const OfficeHourDetail = lazy(
    () => import("components/smart/officeHourDetail/OfficeHourDetail")
)
const EditOfficeHour = lazy(
    () => import("components/smart/editOfficeHour/EditOfficeHour")
)
const UnbookedCheckins = lazy(
    () => import("components/smart/unbookedCheckins/UnbookedCheckins")
)
const ScheduleCheckins = lazy(
    () => import("components/smart/scheduleCheckins/ScheduleCheckins")
)
const CreateBulkCheckins = lazy(
    () => import("components/smart/createBulkCheckins/CreateBulkCheckins")
)
const InstructorAppointments = lazy(
    () =>
        import("components/smart/instructorApointments/InstructorAppointments")
)
const ArchiveOfficeHours = lazy(
    () => import("components/smart/ArchiveOfficeHours/ArchiveOfficeHours")
)
const ShowInstructorCheckin = lazy(
    () => import("components/smart/showInstructorCheckin/ShowInstructorCheckin")
)
const SearchStudent = lazy(
    () => import("components/smart/SearchStudent/SearchStudent")
)
const ShowStudentDetail = lazy(
    () => import("components/smart/showStudentDetail/ShowStudentDetail")
)
const EditCourseRegistration = lazy(
    () =>
        import("components/smart/editCourseRegistration/EditCourseRegistration")
)
const CreateStudent = lazy(
    () => import("components/smart/createStudent/CreateStudent")
)
const MainLayout = lazy(() => import("components/mainLayout/MainLayout"))
const StudentLayout = lazy(
    () => import("components/StudentLayout/StudentLayout")
)
const BlankLayout = lazy(
    () => import("components/publicPages/BlankLayout/BlankLayout")
)
const PublicLayout = lazy(
    () => import("components/publicPages/PublicLayout/PublicLayout")
)
const AdLayout = lazy(() => import("components/adLayout/AdLayout"))
const BooksSection = lazy(
    () => import("components/smart/booksSection/BooksSection")
)
const CheckInSection = lazy(
    () => import("components/smart/checkInSection/CheckInSection")
)
const StudentOfficeHours = lazy(
    () => import("components/smart/studentOfficeHours/StudentOfficeHours")
)
const MyCourse = lazy(() => import("components/smart/myCourse/MyCourse"))
const PdfSection = lazy(() => import("components/smart/pdfSection/PdfSection"))
const PhoneVerifyForm = lazy(
    () => import("components/smart/phoneVerifyForm/PhoneVerifyForm")
)
const OtpVerifyForm = lazy(
    () => import("components/smart/otpVerifyForm/OtpVerifyForm")
)
const PhoneSet = lazy(() => import("components/smart/phoneSet/PhoneSet"))
const VideosSection = lazy(
    () => import("components/smart/videosSection/VideosSection")
)
const LoginForm = lazy(() => import("components/smart/loginForm/LoginForm"))
//Public Pages
const Gre = lazy(() => import("components/publicPages/gre/Gre"))
const AnnualReport = lazy(
    () => import("components/publicPages/annualReport/AnnualReport")
)
const GreOrGmat = lazy(
    () => import("components/publicPages/greOrGmat/GreOrGmat")
)
const Connect = lazy(() => import("components/publicPages/connect/Connect"))
const FAQ = lazy(() => import("components/publicPages/faq/FAQ"))

const FreeTest = lazy(() => import("components/publicPages/freeTest/FreeTest"))
const PrivacyPolicy = lazy(
    () => import("components/publicPages/privacyPolicy/PrivacyPolicy")
)
const DiscussionForum = lazy(
    () => import("components/publicPages/discussionForum/DiscussionForum")
)
const TermsOfService = lazy(
    () => import("components/publicPages/termsOfUse/TermsOfUse")
)
const ContactUs = lazy(
    () => import("components/publicPages/contactUs/ContactUs")
)
const MeetOurLeaders = lazy(
    () => import("components/publicPages/meetOurLeaders/MeetOurLeaders")
)
const CourseWorks = lazy(
    () => import("components/publicPages/courseWorks/CourseWorks")
)
const ComingSoon = lazy(
    () => import("components/publicPages/comingSoon/ComingSoon")
)
const GreNeed = lazy(() => import("components/publicPages/greNeed/GreNeed"))
const AppTimeline = lazy(
    () => import("components/publicPages/appTimeline/AppTimeline")
)
const ForeseeItems = lazy(
    () => import("components/publicPages/foreseeItems/ForeseeItems")
)
const GrePrep = lazy(() => import("components/publicPages/grePrep/GrePrep"))
const GrePrepDiscount = lazy(
    () => import("components/publicPages/grePrepDiscount/GrePrepDiscount")
)
const Partners = lazy(() => import("components/publicPages/partners/Partners"))
//Start of List of Partners
const QuestBridge = lazy(
    () => import("components/publicPages/partners/QuestBridge")
)
const S2s = lazy(() => import("components/publicPages/partners/S2s"))
const Cgaaa = lazy(() => import("components/publicPages/partners/Cgaaa"))
const Sitreps = lazy(() => import("components/publicPages/partners/Sitreps"))
const TheMilitaryVeteran = lazy(
    () => import("components/publicPages/partners/TheMilitaryVeteran")
)
const Boots2Books = lazy(
    () => import("components/publicPages/partners/Boots2Books")
)
const Partner51Vets = lazy(
    () => import("components/publicPages/partners/Partner51Vets")
)
const Avid = lazy(() => import("components/publicPages/partners/Avid"))
const Trf = lazy(() => import("components/publicPages/partners/Trf"))
const LeadershipBrainery = lazy(
    () => import("components/publicPages/partners/LeadershipBrainery")
)
const Qs = lazy(() => import("components/publicPages/partners/Qs"))
//End of List of Partners
const FreeTrial = lazy(() => import("components/smart/freeTrial/FreeTrial"))
const EnrollNow = lazy(() => import("components/smart/enrollNow/EnrollNow"))
const Checkout = lazy(() => import("components/smart/checkout/Checkout"))
const ThankYou = lazy(() => import("components/smart/checkout/ThankYou"))
const SetPasswordPage = lazy(
    () => import("components/smart/setPasswordPage/SetPasswordPage")
)
const RequestPassword = lazy(
    () => import("components/smart/setPasswordPage/RequestPassword")
)

function App() {
    const [showUI, setShowUI] = useState(false)
    const { data, isLoading } = useVerifyMeQuery()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (!isLoading) {
            if (data) {
                // if (
                //     data.user.phone_verification_enabled &&
                //     !data.user.phone_number_verified
                // ) {
                //     dispatch(getUser(data.user))
                //     if (!data.user.phone_number) {
                //         navigate("/auth/phone-set")
                //     } else {
                //         navigate("/auth/phone-verify")
                //     }
                // } else {
                //     dispatch(getVerifySuccess(data.user))
                // }
                if (
                    data.user.email_verification_enabled &&
                    !data.user.email_otp_verified
                ) {
                    navigate("/auth/otp-verify")
                } else {
                    dispatch(getVerifySuccess(data.user))
                }
            } else {
                dispatch(getUserFail())
            }
            setShowUI(true)
        }
    }, [isLoading])
    console.log("App re-wired Testing")
    return (
        <>
            {showUI ? (
                <div className="App">
                    <Helmet>
                        <script src="https://cdn.aplos.com/widgets/donations/1.0.2/donations.min.js"></script>
                        <script>
                            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-P8QX82G'); `}
                        </script>
                        <script>
                            {`(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '20' } }); }; h.appendChild(s); })();`}
                        </script>
                    </Helmet>
                    <ToastContainer />
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Suspense fallback={<></>}>
                                    <PublicLayout />
                                </Suspense>
                            }
                        >
                            <Route index element={<Home />} />
                            <Route
                                path="/privacy-policy"
                                element={
                                    <Suspense fallback={<></>}>
                                        <PrivacyPolicy />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/terms-of-service"
                                element={
                                    <Suspense fallback={<></>}>
                                        <TermsOfService />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="gre-prep"
                                element={
                                    <Suspense fallback={<></>}>
                                        <GrePrep />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="gre-prep-discount"
                                element={
                                    <Suspense fallback={<></>}>
                                        <GrePrepDiscount />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/contact-us"
                                element={
                                    <Suspense fallback={<></>}>
                                        <ContactUs />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/meet-our-leaders"
                                element={
                                    <Suspense fallback={<></>}>
                                        <MeetOurLeaders />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/our-mission"
                                element={<OurMission />}
                            />
                            <Route
                                path="/freetest"
                                element={
                                    <Suspense fallback={<></>}>
                                        <FreeTest />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/test/:id/pdf"
                                element={
                                    <Suspense fallback={<></>}>
                                        <ExamSection/>
                                    </Suspense>
                                }
                            />
                            {/* THIS IS OLD PAGE FOR /gre url */}
                            <Route path="/gre-old" element={<Gre />} />
                            <Route
                                path="/gre"
                                element={
                                    <Suspense fallback={<></>}>
                                        <GrePrep />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/gre-or-gmat"
                                element={<GreOrGmat />}
                            />
                            <Route
                                path="/annual-report"
                                element={<AnnualReport />}
                            />
                            <Route path="/connect" element={<Connect />} />
                            <Route
                                path="/how-our-course-works"
                                element={
                                    <Suspense fallback={<></>}>
                                        <CourseWorks />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/faq"
                                element={
                                    <Suspense fallback={<></>}>
                                        <FAQ />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/gre-need"
                                element={
                                    <Suspense fallback={<></>}>
                                        <GreNeed />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/timeline"
                                element={
                                    <Suspense fallback={<></>}>
                                        <AppTimeline />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/foresee-items"
                                element={
                                    <Suspense fallback={<></>}>
                                        <ForeseeItems />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/partners"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Partners />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/questbridge"
                                element={
                                    <Suspense fallback={<></>}>
                                        <QuestBridge />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/s2s"
                                element={
                                    <Suspense fallback={<></>}>
                                        <S2s />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/cgaaa"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Cgaaa />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/sitreps"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Sitreps />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/themilitaryveteran"
                                element={
                                    <Suspense fallback={<></>}>
                                        <TheMilitaryVeteran />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/boots2books"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Boots2Books />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/51vets"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Partner51Vets />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/avid"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Avid />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/trf"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Trf />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/leadershipbrainery"
                                element={
                                    <Suspense fallback={<></>}>
                                        <LeadershipBrainery />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/qs"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Qs />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route
                            element={
                                <Suspense fallback={<></>}>
                                    <BlankLayout />
                                </Suspense>
                            }
                        >
                            <Route
                                path="/discussion-forum"
                                element={
                                    <Suspense fallback={<></>}>
                                        <DiscussionForum />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route
                            path="/portal"
                            element={
                                <RequireAuth>
                                    <DashBoardLayout />
                                </RequireAuth>
                            }
                        >
                            <Route
                                path="admin/office-hours"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <AdminOfficeHours />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/office-hours/archive"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <ArchiveOfficeHours />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/office-hours/create"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <CreateOfficeHour />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/office-hours/bulk-create"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <CreateBulkOfficeHours />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/office-hours/:id"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <OfficeHourDetail />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/office-hours/edit/:id"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <EditOfficeHour />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/students"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <SearchStudent />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/students/create"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <CreateStudent />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/students/:id"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <ShowStudentDetail />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="admin/students/:student_id/course-registrations/edit/:id"
                                element={
                                    <RequireRole permission="Admin">
                                        <Suspense fallback={<></>}>
                                            <EditCourseRegistration />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="instructor/unbooked-checkins"
                                element={
                                    <RequireRole permission="Instructor">
                                        <Suspense fallback={<></>}>
                                            <UnbookedCheckins />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="instructor/schedule-checkin"
                                element={
                                    <RequireRole permission="Instructor">
                                        <Suspense fallback={<></>}>
                                            <ScheduleCheckins />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="instructor/create-bulk-checkins"
                                element={
                                    <RequireRole permission="Instructor">
                                        <Suspense fallback={<></>}>
                                            <CreateBulkCheckins />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="instructor/appointments"
                                element={
                                    <RequireRole permission="Instructor">
                                        <Suspense fallback={<></>}>
                                            <InstructorAppointments />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="instructor/office_hours/:id"
                                element={
                                    <RequireRole permission="Instructor">
                                        <Suspense fallback={<></>}>
                                            <ShowInstructorCheckin />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                        </Route>
                        <Route
                            path="student"
                            element={
                                <RequireAuth>
                                    <Suspense fallback={<></>}>
                                        <StudentLayout />
                                    </Suspense>
                                </RequireAuth>
                            }
                        >
                            <Route
                                index
                                element={<Navigate to="myCourse" replace />}
                            />
                            <Route
                                path="videos"
                                element={
                                    <RequireRole permission="Student">
                                        <Suspense fallback={<></>}>
                                            <VideosSection />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="books"
                                element={
                                    <RequireRole permission="Student">
                                        <Suspense fallback={<></>}>
                                            <BooksSection />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="books/:id/pdf"
                                element={
                                    <RequireRole permission="Student">
                                        <Suspense fallback={<></>}>
                                            <PdfSection isBook={true} />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="pdf/render"
                                element={
                                    <RequireRole permission="Student">
                                        <Suspense fallback={<></>}>
                                            <ExamSection />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="level_synonyms/:id/pdf"
                                element={
                                    <RequireRole permission="Student">
                                        <Suspense fallback={<></>}>
                                            <PdfSection isBook={false} />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="checkins"
                                element={
                                    <RequireRole permission="Student">
                                        <Suspense fallback={<></>}>
                                            <CheckInSection />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="officeHours"
                                element={
                                    <RequireRole permission="Student">
                                        <Suspense fallback={<></>}>
                                            <StudentOfficeHours />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                            <Route
                                path="myCourse"
                                element={
                                    <RequireRole permission="Student">
                                        <Suspense fallback={<></>}>
                                            <MyCourse />
                                        </Suspense>
                                    </RequireRole>
                                }
                            />
                        </Route>
                        <Route
                            path="auth"
                            element={
                                <ProtectedRouter>
                                    <Suspense fallback={<></>}>
                                        <MainLayout />
                                    </Suspense>
                                </ProtectedRouter>
                            }
                        >
                            <Route
                                path="login"
                                element={
                                    <Suspense fallback={<></>}>
                                        <LoginForm />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="otp-verify"
                                element={
                                    <RequireUser>
                                        <Suspense fallback={<></>}>
                                            <OtpVerifyForm />
                                        </Suspense>
                                    </RequireUser>
                                }
                            />
                            <Route
                                path="phone-verify"
                                element={
                                    <RequireUser>
                                        <Suspense fallback={<></>}>
                                            <PhoneVerifyForm />
                                        </Suspense>
                                    </RequireUser>
                                }
                            />
                            <Route
                                path="phone-set"
                                element={
                                    <RequireUser>
                                        <Suspense fallback={<></>}>
                                            <PhoneSet />
                                        </Suspense>
                                    </RequireUser>
                                }
                            />
                            <Route
                                path="passwords/:token"
                                element={
                                    <Suspense fallback={<></>}>
                                        <SetPasswordPage />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="passwords/new"
                                element={
                                    <Suspense fallback={<></>}>
                                        <RequestPassword />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route
                            path="register"
                            element={
                                <ProtectedRouter>
                                    <Suspense fallback={<></>}>
                                        <MainLayout />
                                    </Suspense>
                                </ProtectedRouter>
                            }
                        >
                            <Route
                                path="free-trial"
                                element={
                                    <Suspense fallback={<></>}>
                                        <FreeTrial />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="enroll"
                                element={
                                    <Suspense fallback={<></>}>
                                        <EnrollNow />
                                    </Suspense>
                                }
                            />
                            <Route
                                path=":id/checkout"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Checkout />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="thank-you"
                                element={
                                    <Suspense fallback={<></>}>
                                        <ThankYou />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </div>
            ) : (
                <AppLoader />
            )}
        </>
    )
}

export default App
